"use client"
import React from "react";
import DarkTheme from "layouts/Dark";
// import { StoryblokComponent, useStoryblokState } from "gatsby-source-storyblok";
import { graphql } from 'gatsby';
import NavbarLR from "components/NavbarLR/navbar";
import Footer from "components/Footer/footer";
import BlogHeader from "components/Blog-Header/blog-header";
import BlogContent from "components/Blog-Content/blog-content";

const IndexPage = ({ data }) => {
  // let story = data.storyblokEntry
  // story = useStoryblokState(story)

  // const components = story.content.body.map(blok => (<StoryblokComponent blok={blok} key={blok._uid} />))
  const fixedHeader = React.useRef(null);
  const MainContent = React.useRef(null);
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    setTimeout(() => {
      if (fixedHeader.current) {
        var slidHeight = fixedHeader.current.offsetHeight;
      }
      if (MainContent.current) {
        MainContent.current.style.marginTop = slidHeight + "px";
      }
    }, 1000);

    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [fixedHeader, MainContent, navbarRef]);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 150) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 150) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme mobileappstyle={true}>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>

      <NavbarLR nr={navbarRef}  lr={logoRef}/>
      <BlogHeader sliderRef={fixedHeader} title="History and Story" philigram="Story" />
      <div ref={MainContent} className="main-content">
        <BlogContent>
        <div className="about col-lg-12">
            <div className="img-mons sm-mb30">
              <div className="row">
                <div className="col-md-12 cmd-padding valign">
                  <div className="img2 wow imago" data-wow-delay=".5s">
                    <img src="/img/project/virtual-visit1.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <h3 className="extra-title">What, Where, and When</h3> */}
          <p className="spacial">
            The old recycling shed, as it is now known, is set to become the latest community asset by way of a physical fitness facility, with a strong focus on martial arts, in particular ju-jitsu & kickboxing.  With permanent mats installed the space will be able to be utilised for gymnastics, yoga, pilates and more.
          </p>
          <div className="col-lg-12">
            <div className="img-mons sm-mb30">
              <div className="row">
                <div className="col-md-12 cmd-padding">
                  <div className="img2 wow imago" data-wow-delay=".3s">
                    <img src="/img/project/virtual-visit2.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="spacial">
          The building started off in life as a shed for manufacturing power poles that were then transported by train to be used in Christchurch.  Once the railway line was closed the building was no longer used as a power pole factory.  It was then modified and became the Little River Fire Station until the new fire station was built in 1982.
          </p>
          <div className="about col-lg-12">
            <div className="img-mons">
              <div className="row">
                <div className="col-md-12 cmd-padding valign">
                  <div className="img1 wow imago" data-wow-delay=".5s">
                    <img src="/img/project/visit-old-shed1.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="spacial">
          The building was later used as a recycling facility employing people from the Works Peninsula program.  This closed down early 2000’s and had been privately leased by various community people, for instance a local carpenter used it as a joinery workshop.  It was then purchased by Little River Ju-Jitsu club in around 2018 to be converted into a community facility.
          </p>
          <div className="about col-lg-12">
            <div className="img-mons">
              <div className="row">
                <div className="col-md-6 cmd-padding valign">
                  <div className="img1 wow imago" data-wow-delay=".3s">
                    <img src="/img/project/visit-old-shed2.jpg" alt="" />
                  </div>
                </div>
                <div className="col-md-6 cmd-padding">
                  <div className="img1 wow imago" data-wow-delay=".8s">
                    <img src="/img/project/visit-old-shed3.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="spacial">
          The club has struggled with funding over the years for this project and finally, out of the blue, the amazing Jordan Luck approached us with the idea of a benefit concert to raise money, and has generously donated his name, time and band to the project.
          </p>
          <div className="about col-lg-12">
            <div className="img-mons">
              <div className="row">
                <div className="col-md-6 cmd-padding">
                  <div className="img1 wow imago" data-wow-delay=".3s">
                    <img src="/img/project/visit-old-shed4.jpg" alt="" />
                  </div>
                </div>
                <div className="col-md-6 cmd-padding">
                  <div className="img1 wow imago" data-wow-delay=".8s">
                    <img src="/img/project/visit-old-shed5.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </BlogContent>
      </div>
      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Little River Music Festival</title>
      <meta property="og:title" content="Little River Music Festival - History & Story" />
      <meta property="og:description" content="Discover the inspiring journey of Little River's old recycling shed, transformed into a thriving Jujitsu Dojo. Join our festival to support its legacy and future." />
      <meta property="og:image" content="https://festival.littleriver.co.nz/img/project/virtual-visit1.jpg" />
      <meta property="og:type" content="article" />
      <meta property="og:url" content="https://festival.littleriver.co.nz/about/history-story/" />
    </>
  )
}

export default IndexPage;

export const query = graphql`
  query HomeQuery {
    storyblokEntry(full_slug: { eq: "home" }) {
      content
      name
      full_slug
      uuid
      id
      internalId
    }
  }
`